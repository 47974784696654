/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./Layout.vue?vue&type=template&id=47df251f&scoped=true"
var script = {}
import style0 from "./Layout.vue?vue&type=style&index=0&id=47df251f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_7gd5tyclgk24q7xtfr4u5piptm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47df251f",
  null
  
)

export default component.exports