/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./PageHeader.vue?vue&type=template&id=30e2e8d0&scoped=true"
import script from "./PageHeader.vue?vue&type=script&setup=true&lang=js"
export * from "./PageHeader.vue?vue&type=script&setup=true&lang=js"
import style0 from "./PageHeader.vue?vue&type=style&index=0&id=30e2e8d0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_7gd5tyclgk24q7xtfr4u5piptm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30e2e8d0",
  null
  
)

export default component.exports